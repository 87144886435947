import "../../global.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { publicRequest, urlAdminMooda, urlMooda } from "../../requestMethods";
import { formatTimeYYYYMMDD } from "../../util/helper";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Container from "@mui/material/Container";
import { formatPricingDatabase } from "../../util/helper";

const Chargeback = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [inputs, setInputs] = useState({});
  const [dataIni, setDateIni] = useState();
  const [valueChargeback, setValueChargeback] = useState();
  const [companyId, setCompanyId] = useState();
  const [companies, setCompanies] = useState();

  const [plano, setPlano] = useState("");
  const [criado, setCriado] = useState(false);
  const [showDashboard, setShowDashboard] = useState();
  const [showFrete, setShowFrete] = useState();
  const [razao_social, setRzao_social] = useState();
  const [socio, setSocio] = useState();
  const [end_empresa, setEnd_empresa] = useState();
  const [end_num_empresa, setEnd_num_empresa] = useState();
  const [end_comp_empresa, setEnd_comp_empresa] = useState();
  const [end_bairro, setEnd_bairro] = useState();
  const [end_cep, setEnd_cep] = useState();
  const [end_ddd, setEnd_ddd] = useState();
  const [end_tel, setEnd_tel] = useState();
  const [end_estado, setEnd_estado] = useState();
  const [end_cidade, setEnd_cidade] = useState();
  const [cnpj_cpf, setCnpj_cpf] = useState();
  const [cep, setCep] = useState("");
  const [street, setStreet] = useState("");
  const [complement, setComplement] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [states, setStates] = useState([]);
  const [dataNiver, setDateNiver] = useState();
  const [tipoTelPay, setTipoTelPay] = useState();
  const [tipoTelEmp, setTipoTelEmp] = useState();

  useEffect(() => {
    getCompanies();
  }, []);

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const getCompanies = async () => {
    const res = await publicRequest.get(`/company`);

    setCompanies(res.data);
  };

  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeFullPrice = (e) => {
    setValueChargeback(formatPricingDatabase(e.target.value));
  };
  const handleChangeCompany = (e) => {
    setCompanyId(e.target.value);
  };

  function validate() {
    if (
      inputs.paymentReferenceIdResponse === "" ||
      inputs.paymentReferenceIdResponse === undefined
    ) {
      Swal.fire("Atenção!", "Código de referência Pagbank obrigatório");
      return false;
    }
    if (!valueChargeback || valueChargeback <= 0) {
      Swal.fire("Atenção!", "Valor do chargeback obrigatório");
      return false;
    }
    if (!companyId) {
      Swal.fire("Atenção!", "Empresa obrigatório");
      return false;
    }
    if (inputs.comment === "" || inputs.comment === undefined) {
      Swal.fire("Atenção!", "Observação obrigatório");
      return false;
    }

    return true;
  }
  const insertCahargeback = async () => {
    const res = await publicRequest.post(`0/chargeback/registerChargeback`, {
      paymentReferenceIdResponse: inputs.paymentReferenceIdResponse,
      amount: valueChargeback,
      dateChargeback: dataIni,
      companyId: companyId,
      origin: currentUser.name,
      comment: inputs.comment,
    });

    if (Object.keys(res.data).length > 0) {
      Swal.fire("Parabéns!", "Chargeback criado com sucesso!", "success");
    } else {
      Swal.fire("Atenção!", "Erro ai criar chargeback");
    }
  };
  const handleClick = () => {
    if (validate()) {
      insertCahargeback();
    }
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <div className="div_custom_Form">
        <span className="div_custom_UpdateTitle">Novo Cliente MoOda</span>
        <div className="div_custom_UpdateLeft">
          <div className="div_custom_UpdateItem">
            <label>Código de referência Pagbank</label>
            <input
              type="text"
              name="paymentReferenceIdResponse"
              className="div_custom_UpdateInput"
              onChange={handleChange}
              maxLength="200"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Valor Chargeback</label>
            <input
              type="text"
              name="amount"
              className="div_custom_UpdateInput"
              onChange={handleChangeFullPrice}
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Data de Validade</label>
            <input
              type="date"
              name="dateChargeback"
              value={dataIni}
              className="div_custom_UpdateInput"
              onChange={handleChangeStart}
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Empresas</label>
            <select
              className="div_custom_Select"
              name="company"
              onChange={handleChangeCompany}
            >
              <option value=""></option>
              {companies
                ? companies.map((item) => (
                    <option value={item.companyId}>{item.name}</option>
                  ))
                : false}
            </select>
          </div>
          <div className="div_custom_UpdateItem">
            <label>Observação</label>
            <input
              type="text"
              name="comment"
              className="div_custom_UpdateInput"
              onChange={handleChange}
              maxLength="200"
            />
          </div>
        </div>
        <br></br>
        <div className="div_custom_UpdateRight">
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<SaveIcon />}
            onClick={handleClick}
          >
            Cadastrar
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Chargeback;
